import {EContactType} from "./contact-type.model";
import {IAttachment} from "./attachment.model";
import {JSONSchema7} from "json-schema";
import {i18n} from "../services";

interface ILocal {
    "com.blocksure.initialProductId": string;
    "com.blocksure.initialProductVersion": string;
    marketingApproval: string;
}

interface ISharedInfo {
    address: {
        country: string;
        line1: string;
        line2: string;
        line3: string;
        line4: string;
        postcode: string;
        subzone: string;
        zone: string;
    };
    appPath: string;
    contact: {
        contactMethod: string;
        primaryEmailAddress: string;
        primaryPhoneNumber: string;
    };
    locale: string;
    personal: {
        dateOfBirth: string;
        firstName: string;
        firstNameFurigana?: string;
        lastName: string;
        lastNameFurigana?: string;
        title: string;
    };
    schemaCountry?: string;
    lastNameFurigana?: string;
    firstNameFurigana?: string;
}

export interface IPolicyholder {
    alias: string;
    attachments: IAttachment[];
    dateCreated: string;
    id: string;
    local: ILocal;
    primaryEmailAddress: string;
    primaryPhoneNumber: string;
    productId: string;
    shared: ISharedInfo;
    ts: string;
    type: EContactType;
    version: number;
}

const markRequired = (scheme: JSONSchema7) => {
    Object.keys(scheme.properties).forEach((key: any) => {
        if (!(scheme.properties[key] as JSONSchema7).title) {
            (scheme.properties[key] as JSONSchema7).title = i18n.t(key);
        }
    });

    // add * to the required labels
    scheme.required.forEach((property) => {
        const obj = scheme.properties[property] as JSONSchema7;

        if (obj.title) {
            obj.title += " *";
        }
    });
};

export const policyholderSchema = (universalSchema: JSONSchema7, countryCode: string): { schema: JSONSchema7; uiSchema: JSONSchema7 } => {
    const { address, contact, personal } = universalSchema.properties;

    if (countryCode === "JP") {
        (address as JSONSchema7).required = (address as JSONSchema7).required.filter((it: string) => it !== "country");
        delete (address as JSONSchema7).properties.country;
    }

    const scheme = {
        type: "object",
        properties: {
            personal: {
                type: "object",
                title: i18n.t("personalDetails"),
                required: (personal as JSONSchema7).required,
                properties: {
                    firstName: {
                        type: "string",
                    },
                    lastName: {
                        type: "string",
                    },
                    dateOfBirth: {
                        type: "string",
                        format: "date",
                    },
                },
                "ui:order": (personal as JSONSchema7)["ui:order"],
            },
            contact: {
                type: "object",
                title: i18n.t("contactPreference"),
                required: (contact as JSONSchema7).required,
                properties: {
                    primaryEmailAddress: {
                        type: "string",
                        title: i18n.t("email"),
                    },
                    primaryPhoneNumber: {
                        type: "string",
                        title: i18n.t("mobile"),
                    },
                    contactMethod: {
                        type: "string",
                        title: i18n.t("contactPreference"),
                        enum: ["LETTER_HOME", "LETTER_DIFF", "EMAIL", "PHONE", "TEXT", "LANDLINE"],
                        enumNames: [
                            i18n.t("postHomeAddress"),
                            i18n.t("postDifferentAddress"),
                            i18n.t("email"),
                            i18n.t("mobile"),
                            i18n.t("text"),
                            i18n.t("landline")
                        ],
                    },
                },
                "ui:order": (contact as JSONSchema7)["ui:order"],
            },
            address: {
                type: "object",
                title: i18n.t("addresses"),
                required: (address as JSONSchema7).required,
                properties: {
                    line1: {
                        type: "string",
                    },
                    line2: {
                        type: "string",
                    },
                    line3: {
                        type: "string",
                    },
                    line4: {
                        type: "string",
                    },
                    subzone: {
                        type: "string",
                    },
                    zone: {
                        type: "string",
                    },
                    postcode: {
                        type: "string",
                    },
                },
                "ui:order": (address as JSONSchema7)["ui:order"],
            },
        },
    } as JSONSchema7;

    if (countryCode !== "JP") {
        (scheme.properties.personal as JSONSchema7).properties.title = {
            type: "string",
            enum: ["", i18n.t("dr"), i18n.t("miss"), i18n.t("mr"), i18n.t("mrs"), i18n.t("ms"), i18n.t("prof")],
            enumNames: ["---", i18n.t("dr"), i18n.t("miss"), i18n.t("mr"), i18n.t("mrs"), i18n.t("ms"), i18n.t("prof")],
        } as JSONSchema7;
        (scheme.properties.address as JSONSchema7).properties.country = {
            type: "string",
        } as JSONSchema7;
    } else {
        (scheme.properties.personal as JSONSchema7).properties.firstNameFurigana = {
            type: "string",
            title: i18n.t("firstNameFurigana"),
        } as JSONSchema7;
        (scheme.properties.personal as JSONSchema7).properties.lastNameFurigana = {
            type: "string",
            title: i18n.t("lastNameFurigana"),
        } as JSONSchema7;
    }

    universalSchema["ui:order"].forEach((property) => markRequired(scheme.properties[property] as JSONSchema7));

    return processSchema(scheme);
};

export const validatePhoneNumberFn = {
    GB: (value) => value === "" || value.match(/^0(|7|7[0-9]{0,9})$/) || value.match(/^\+(|4|44|447|447[0-9]{0,9})$/),
    default: (value) => value === "" || value.match(/^[+0-9]+$/),
};

function processSchema(raw) {
    const schema: JSONSchema7 = {};
    const ui: JSONSchema7 = {};

    // Split the 'UI schema' out from the vanilla 'JSON schema'.
    if (raw)
        Object.keys(raw).forEach((key) => {
            const value = raw[key];
            // Whitelist of properties to move over to the UI.
            if (key === "classNames" || key.startsWith("ui:") || key.startsWith("fm:")) {
                ui[key] = value;
                // If the value is an object, then we recurse.
            } else if (value && typeof value === "object" && !Array.isArray(value)) {
                const result = processSchema(value);
                if (Object.keys(result.schema).length > 0) schema[key] = result.schema;
                if (result.uiSchema && Object.keys(result.uiSchema).length > 0) ui[key] = result.uiSchema;
                // For all other values, we just set it into the new schema.
            } else {
                schema[key] = value;
            }
        });

    // The UI schema inexplicably does away with the 'properties' object in its schema, so we move all of its properties up to the level above and remove it.
    if ((ui as any).properties) {
        Object.keys((ui as any).properties).forEach((key) => {
            ui[key] = (ui as any).properties[key];
        });
        delete (ui as any).properties;
    }
    return { schema, uiSchema: ui };
}
