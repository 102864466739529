export const policyholderMock = {
    alias: "",
    attachments: null,
    dateCreated: "2020-01-07",
    id: "pho_123",
    local: {
        "com.blocksure.initialProductId": "pro_123",
        "com.blocksure.initialProductVersion": "1",
        marketingApproval: "true",
    },
    primaryEmailAddress: "chris.hamilton+1@blocksure.com",
    primaryPhoneNumber: "12345678900",
    productId: "prd_123",
    shared: {
        address: {
            country: "UK",
            line1: "11 Ripley Drive",
            line2: "",
            line3: "",
            line4: "",
            subzone: "Harrogate",
            zone: "North Yorkshire",
            postcode: "HG13JD",
        },
        appPath: "/stu",
        contact: {
            contactMethod: "",
            primaryEmailAddress: "joe.greene+derek.stump@blocksure.com",
            primaryPhoneNumber: "07123213123",
        },
        personal: {
            dateOfBirth: "1990-02-02",
            lastName: "Roy",
            firstName: "Jhon",
            title: "mr",
        },
        locale: "en",
        schemaCountry: "GB",
    },
    ts: "2020-08-07",
    type: "Individual",
    version: 1,
};

export const policyholderJapaneseMock = {
    alias: "",
    attachments: null,
    dateCreated: "2020-01-07",
    id: "pho_123",
    local: {
        "com.blocksure.initialProductId": "pro_123",
        "com.blocksure.initialProductVersion": "1",
        marketingApproval: "true",
    },
    primaryEmailAddress: "yoshihide.suga@blocksure.com",
    primaryPhoneNumber: "12345678900",
    productId: "prd_123",
    shared: {
        address: {
            country: "日本",
            line1: "シティアベニュー札幌 R603",
            line2: "２丁目１−2",
            line3: "",
            line4: "",
            subzone: "豊平区",
            zone: "北海道県",
            postcode: "062-0911",
        },
        appPath: "/sci",
        contact: {
            contactMethod: "",
            primaryEmailAddress: "yoshihide.suga@blocksure.com",
            primaryPhoneNumber: "07123213123",
        },
        personal: {
            dateOfBirth: "1990-02-02",
            lastName: "本lastName",
            firstName: "日firstName",
            lastNameFurigana: "本lastNameFurigana",
            firstNameFurigana: "日firstNameFurigana",
            title: "",
        },
        schemaCountry: "JP",
        locale: "ja-JP",
    },
    ts: "2020-08-07",
    type: "Individual",
    version: 1,
};

export const companyMock = {
    ...policyholderMock,
    type: "Company",
    shared: {
        ...policyholderMock.shared
    },
};
